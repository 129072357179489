import { Suspense, lazy, useContext } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoaderScreen from "../pages/LoaderScreen";
import ProtectedRoute from "../authenticate/protectedRoutes";
import PublicRoute from "../authenticate/publicRoutes";
import { UserContext } from "../context/UserProvider";

const CartScreen = lazy(() => import("../pages/CartScreen"))
const CartScreenGourmet = lazy(() => import("../CartaGourmet/app/pages/CartScreen"))
const CheckoutScreen = lazy(() => import('../pages/CheckoutScreen/CheckoutScreen'))
const MainScreen = lazy(() => import('../pages/MainScreen/MainScreen'))
const MainScreenGourmet = lazy(() => import('../CartaGourmet/app/pages/MainScreen/MainScreen'))
const LandingScreen = lazy(() => import('../pages/LandingScreen'))



const index = () => {

  const {isGourmet, setIsGourmet} = useContext(UserContext)

  return (
    <Suspense fallback={<></>}>
      <BrowserRouter>
        <Routes>
          {/* Public rutes */}
          {/* <Route path="status/404" element={<Status404 />} />           
            <Route path="*" element={<Status404 />} /> */}
          <Route path="/" element={<LandingScreen />} />  
          {/* ROUTE DEL MAIN (PUBLICAS) */}
          <Route path="/:id/:idmesa" element={(isGourmet) ? <MainScreenGourmet /> : <MainScreen />} />
           {/* ROUTE DEL MAIN (PRIVADAS) */}        
          <Route path="/listapedido/:id/:idmesa/:numero" element={(isGourmet) ? <CartScreenGourmet /> : <CartScreen />} />          
          <Route path="/checkout/:id/:idmesa/:numero" element={<CheckoutScreen />} />          
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

export default index;
