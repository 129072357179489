import { CONFIG } from './configuracion.service';

const url = CONFIG.url + CONFIG.versionApi;

export const getUsers = async (idtoken: string) => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + idtoken
            }
        };

        const response = await fetch(`${url}/account`, requestOptions);
        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.log(error);
    }
}
export const getCards = async (idtoken: string) => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + idtoken
            }
        };

        const response = await fetch(`${url}/payment/account/card/list`, requestOptions);
        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.log(error);
    }
}