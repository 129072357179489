// eslint-disable-next-line no-restricted-globals
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AxiosInterceptor } from './app/interceptors/axios.interceptors';
import { store } from './app/redux/store';
import { Provider } from 'react-redux';

import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next';
import global_es from "./app/CartaGourmet/app/translations/es/global.json"
import global_en from "./app/CartaGourmet/app/translations/en/global.json"

i18next.init({
  interpolation: {
    escapeValue: false
  },
  lng: localStorage.getItem('language') === 'Español' ? 'es' : 'en',
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en
    }
  }
})



AxiosInterceptor();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Provider>
  // </React.StrictMode>
);