import { FormatPrice } from "../models/cart";

export const TransformaMonto = (amount: number | null) => {
    return (amount !== null && Number(amount.toString().substring(0, amount.toString().length - 2) + '.'
        + amount.toString().substring(amount.toString().length - 2, amount.toString().length)).toFixed(2));
}

export function GetRandomInteger(min: any, max: any): number {
    if (isNaN(min) || isNaN(max)) {
        throw new Error("min and max must be a number");
    }
    min = Number(min);
    max = Number(max);
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const precioFormat = (id: string, amount: number) : FormatPrice => {
    const precio: FormatPrice = {
      id: null,
      amount: amount,
      currency_code: 'PEN',
      formatted_amount: 'S/.' + TransformaMonto(amount)
    };
    return precio;
}

export const rellenarCero = (number:number, width:number) => {
    var numberOutput = Math.abs(number); /* Valor absoluto del número */
    var length = number.toString().length; /* Largo del número */ 
    var zero = "0"; /* String de cero */  
    
    if (width <= length) {
        if (number < 0) {
             return ("-" + numberOutput.toString()); 
        } else {
             return numberOutput.toString(); 
        }
    } else {
        if (number < 0) {
            return ("-" + (zero.repeat(width - length)) + numberOutput.toString()); 
        } else {
            return ((zero.repeat(width - length)) + numberOutput.toString()); 
        }
    }
}

export const separarFecha = (i:number, response: any) =>{

    // console.log(response);
    

    const fecha = response.payment.fechaTransaction.split("");        
    return fecha[i];
}  