import axios, { AxiosRequestConfig } from "axios"

export const AxiosInterceptor = () => {
  const updateHeader = (request: AxiosRequestConfig) => {
    const token = localStorage.getItem('idstore');
    const newHeaders: any = {
      Authorization: token,
      "Content-Type": "application/json"
    };
    request.headers = newHeaders;
    return request;
  }

  axios.interceptors.request.use((request) => {
    if (request.url?.includes("assets")) return request
    return updateHeader(request)
  });

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      return Promise.reject(error)
    }
  )
};