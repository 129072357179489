export const CONFIG ={
  url: 'https://api.patiodecomidas.pe/',    
  url_socket: 'https://api.patiodecomidas.pe/notify',
  versionApi: 'api',
  secret: 'infomatica@12345678*',
  merchanIdNiubis: '522591303',
};

export const firebaseConfig = {
  apiKey: "AIzaSyDGkB_iVPIiQve-rnF8DmTRIVvQQZja4ug",
  authDomain: "carta-digital-f4605.firebaseapp.com",
  projectId: "carta-digital-f4605",
  storageBucket: "carta-digital-f4605.appspot.com",
  messagingSenderId: "272883385539",
  appId: "1:272883385539:web:86c6019bd629f725fd0e76",
  measurementId: "G-EQPS3CJEYB"
};

export const loginDefault = {
  displayName: "Cliente General",
  email: "clientegeneral.carta@gmail.com",
  phoneNumber: null,
  photoURL: "https://lh3.googleusercontent.com/a/AEdFTp7EcJAkBT_RDboPsPSuYTUv1W46ccS9LqVg2FM-=s96-c",
  providerId: "google.com",
  uid: "100281741246419720464"
};