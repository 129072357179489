import { Provider } from 'react-redux';
import './App.css';
import Router from "./app/router";
import { UserProvider } from './app/context/UserProvider';
import { UserProvider as UserProviderGourmet } from './app/CartaGourmet/app/context/UserProvider';
const App = () => {
  return(
  
    <UserProvider>  
      <UserProviderGourmet>   
        <Router/>
        </UserProviderGourmet>       
    </UserProvider>
 
  );
}



export default App;
