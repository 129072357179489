import axios from "axios";
import { CONFIG } from "../services/configuracion.service";

const url = CONFIG.url + CONFIG.versionApi;
let token = JSON.parse(localStorage.getItem('token') || '1');

export const getCartaPago = async (optionalAuth: boolean, id: string) => {
  try {
    const response = await axios.get<any>(`${url}/cartadigital/payment/${id}`, {
      headers: {
        'Authorization': optionalAuth ? `Bearer ${token}` : undefined,
        'Content-Type': 'application/json'
      }
    });
    return response;
  } catch (error: any) {
    console.log('getCartaPago', error.message);
  }
}