import React, { useState, useContext } from 'react';
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup, signInWithRedirect, UserCredential } from 'firebase/auth';
import { firebaseConfig } from "./configuracion.service";
import { LoginGoogle } from "./storage.service";
import Cookies from 'universal-cookie';
import { getUsers } from "./users.service";
import { SessionAuth } from "../models/authUser";
import { UserContext } from '../context/UserProvider';



const appfirebase = initializeApp(firebaseConfig);
export const auth = getAuth(appfirebase);
auth.languageCode = 'pe';

export const signInWithGoogle = () => {
   
    const provider = new GoogleAuthProvider();
    provider.addScope('profile email');
    signInWithPopup(auth,provider).then((result) => {
        const user = result.user
        // console.log(user?.providerData[0]);
        
        LoginGoogle(user.providerData[0]).then(
            (result) => {

                console.log(`Guardando token en storage ${result?.data.token}`);
                

                localStorage.setItem('user_token', result?.data.token!);
            }
        )
        
    });    
};

export const signInWithApple = () => {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    signInWithPopup(auth,provider);
};

export const verifyLogin = async (responseUser:UserCredential) => {
    console.log(responseUser)
    const token = await getTokenSession(responseUser);    
    
    if(token !== null || token !== undefined){
         
      
        // if(responseUser.providerId === 'facebook.com'){

        // }
        // if(responseUser.providerId === 'google.com'){            
        //     const sesionLogin = await LoginGoogle(responseUser.user.providerData[0]).then(result =>
        //         result as SessionAuth);
        //     //cookies.set('user_token_carta', JSON.stringify(sesionLogin), { expires : new Date(sesionLogin.expiration), path: '/',  domain: location.hostname, secure: false, sameSite: 'strict' });
        //     localStorage.setItem("user_token_carta", sesionLogin.token);
        //     await getUsers(sesionLogin.token).then(result =>
        //     localStorage.setItem('user-data', JSON.stringify(result.data))

        //     );
        // }


        // if(responseUser.providerId === 'apple.com'){
        
        // }
    }
    else{
        
    }
}

export const getTokenSession = async (responseUser:UserCredential) => {   
    
    const response = await responseUser.user.getIdToken()
    .then(result => result)
    .catch(error => error); 
    
    return response;
   
}
 